// Module
var code = `<!--QUESTION-->
<div class="multichoice">
  <div class="questionarea clearfix">
    <div class="questionask">
      <% if (variant.questionImg) { %>
      <button class="questionimage imgwrap zoom-image-wrap" data-media-id="<%- variant.questionImg.preferred.id %>"></button>
      <% } %>
      <span class="question-text"><%= variant.questionText %></span>
    </div>
    <div class="clear"></div>
    <div class="bonuscharacter" <% if (bonuscharacter === 'NoCoach') { %>aria-hidden="true" role="presentation"<% } %>>
      <div role="img" aria-label="<%- t('coaches.thinking') %>" class="<%- bonuscharacter %> ponder"></div>
    </div>
  </div>
  <div class="hinttext" aria-live="polite">
    <div role="heading" aria-level="3" class="question-instruction clearfix"></div>
  </div>
  <!--ANSWERS-->
  <div class="answerwrappersize">
    <div class="answerwrapper" role="radiogroup" aria-label="<%= t('question.accessibility.multichoiceGroupLabel', {questionText: variant.questionText}) %>"></div>
    <label class="hidden" id="correct-label-accessibility"><%- t('question.accessibility.correctAnswer') %></label>
    <label class="hidden" id="incorrect-label-accessibility"><%- t('question.accessibility.incorrectAnswer') %></label>
    <p class="off-screen" id="accessibility-note"><%- t('question.accessibility.multichoiceInstructions') %></p>
  </div>
</div>
`;
// Exports
module.exports = code;