// Module
var code = `<div class="survey">
  <div id="survey-banner" class="banner">
    <p><%- t('question.surveyBanner') %></p>
  </div>

  <div class="questionarea clearfix">
    <div id="questionask" class="questionask" aria-labelledby="survey-banner questionask">
      <% if (variant.image) { %>
        <button class="questionimage imgwrap zoom-image-wrap image-wrapper" data-media-id="<%- variant.image.preferred.id %>"></button>
      <% } %>
      <span class="question-text"><%= variant.question %></span>
    </div>
    <div class="clear"></div>
  </div>

  <% var cols = variant.allowAnswer3 === true ? 'cols-5' : 'cols-4'; %>
  <div class="answerwrappersize <%- cols %>">
    <div class="answerwrapper clearfix" role="radiogroup"></div>
  </div>

  <% if (variant.allowComments) { %>
  <div class="comment">
    <h3 id="comment-label"><%- t('question.surveyComments') %></h3>
    <div class="comment-wrapper">
      <fieldset><!-- the fieldset & div wrapper is for IE6 -->
        <div>
          <textarea aria-labelledby="comment-label"></textarea>
        </div>
      </fieldset>
    </div>
  </div>
  <% } %>
  <% if (variant.allowOptOut) { %>
    <div class="opt-out answerwrapper"></div>
  <% }; %>
  <p class="off-screen" id="accessibility-note"><%- t('question.accessibility.surveyInstructions') %></p>
</div>

`;
// Exports
module.exports = code;