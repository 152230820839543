import I18n from '@common/libs/I18n';
import {
  AxIcon,
  AxIconButton,
  AxInputAdornment,
  AxTextField
} from '@common/modules/react/themes/components';
import { useDateTimeStyles } from '@common/modules/react/themes/components/mui-x/AxDatePicker/useDateTimeStyles';
import { styled } from '@mui/material';
import {
  LocalizationProvider,
  DateTimePicker as MuiDateTimePicker,
  type DateTimePickerProps as MuiDateTimePickerProps,
  type PickerValidDate as MuiPickerValidDate
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  forwardRef,
  type Ref
} from 'react';

export interface AxDateTimePickerProps<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> extends MuiDateTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {
  required?: boolean;
}

const styledOptions = {
  name: 'AxDateTimePicker'
};

const StyledAxDateTimePicker = styled(MuiDateTimePicker, styledOptions)(() => {
  // AxDatePicker uses different components (slots) for the text field and input adornment.
  // The styles are defined within the components.
  return {};
});

/**
 * The DateTime Picker component lets the user select a date and time.
 *
 * #### Links
 * - • [MUI Demo](https://mui.com/x/react-date-pickers/date-time-picker/)
 * - • [MUI API](https://mui.com/x/api/date-pickers/date-time-picker/)
 *
 */
export const AxDateTimePicker = forwardRef(<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
>(
    {
      slots = {},
      slotProps = {},
      format = 'LL hh:mm a',
      required = false,
      disablePast = false,
      ...DateTimePickerProps
    }: AxDateTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure>,
    ref: Ref<HTMLDivElement>
  ) => {
  return (
    <LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale='userLocale'>
      <StyledAxDateTimePicker
        format={ format }
        disablePast={ disablePast }
        { ...DateTimePickerProps }
        slots={{
          ...slots,
          textField: AxTextField,
          inputAdornment: AxInputAdornment,
          openPickerButton: AxIconButton,
          openPickerIcon: AxIcon
        }}
        slotProps={{
          ...slotProps,
          textField: {
            required: required,
            placeholder: I18n.t('mui.datetimepicker.selectDateTime'),
            ...(slotProps?.textField ?? {}),
            sx: {
              input: {
                // Remove the background color and border on hover and focus on mobile devices
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                  border: 'none'
                }
              }
            }
          },
          openPickerButton: {
            size: 'medium',
            ...(slotProps?.openPickerButton ?? {})
          },
          openPickerIcon: {
            className: 'icon-frequenttraining',
            ...(slotProps?.openPickerIcon ?? {})
          },
          toolbar: {
            sx: {
              '& .MuiDateTimePickerToolbar-timeDigitsContainer': {
                alignItems: 'center'
              }
            }
          },
          layout: {
            sx: {
              ...useDateTimeStyles(),
              ...slotProps?.layout?.sx
            }
          }
        }}
        ref={ ref }
      />
    </LocalizationProvider>
  );
});

export default AxDateTimePicker;

